import React, { Component } from 'react'
import M from "materialize-css/dist/js/materialize.min.js";
import { Link as Link2 } from 'react-router-dom'
import "materialize-css/dist/css/materialize.min.css";
import { Link } from "react-scroll";
class Navbar extends Component {
    componentDidMount() {
        var elem = document.querySelector(".sidenav");
        M.Sidenav.init(elem, {
            edge: "left",
            inDuration: 250
        });
    }
    render() {
        return (
            <div className="navig">
                <nav id="menu" className="nav-wrapper grey darken-3">
                    <div className="container">
                        <Link2 to='/' className="brand-logo">Yash Agarwal</Link2>
                        <a href="#" data-target="mobile-demo slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                        <ul id="nav-mobile" className="right hide-on-med-and-down">
                            <li>
                                <Link
                                    activeClass="active"
                                    to="home"
                                    spy={true}
                                    smooth={true}
                                    offset={-20}
                                    duration={500}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link
                                    activeClass="active"
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-20}
                                    duration={500}>
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link
                                    activeClass="active"
                                    to="resume"
                                    spy={true}
                                    smooth={true}
                                    offset={-20}
                                    duration={500}>
                                    Resume
                                </Link>
                            </li>
                            <li>
                                <Link
                                    activeClass="active"
                                    to="projects"
                                    spy={true}
                                    smooth={true}
                                    offset={-20}
                                    duration={500}>
                                    Projects
                                </Link>
                            </li>
                            <li>
                                <Link
                                    activeClass="active"
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    offset={-20}
                                    duration={500}>
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="sidenavig">
                    <ul className="sidenav" id="mobile-demo slide-out" >
                        <br /><br /><br />
                        <li>
                            <Link
                                activeClass="active"
                                to="home"
                                spy={true}
                                smooth={true}
                                offset={-20}
                                duration={500}>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClass="active"
                                to="about"
                                spy={true}
                                smooth={true}
                                offset={-20}
                                duration={500}>
                                About
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClass="active"
                                to="resume"
                                spy={true}
                                smooth={true}
                                offset={-20}
                                duration={500}>
                                Resume
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClass="active"
                                to="projects"
                                spy={true}
                                smooth={true}
                                offset={-20}
                                duration={500}>
                                Projects
                                </Link>
                        </li>
                        <li>
                            <Link
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-20}
                                duration={500}>
                                Contact
                                </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navbar