import React from 'react'
import Slider from "react-slick";
import Navbar2 from '../layout/Navbar2'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DITAppLogo from '../assets/img/ditapplogo.png'
import DITAppStats from '../assets/img/dituappstats.png'
import { Link } from 'react-router-dom'
import ditapp1 from '../assets/img/ditapp1.webp';
import ditapp2 from '../assets/img/ditapp2.webp';
import ditapp3 from '../assets/img/ditapp3.webp';
import ditapp4 from '../assets/img/ditapp4.webp';
import ditapp5 from '../assets/img/ditapp5.webp';
import ditapp6 from '../assets/img/ditapp6.webp';
import playstore from '../assets/img/playstore.png';
import github from '../assets/img/github.png'
class DituApp extends React.Component {
    state = {
        open: false
    };
    sr="";
    openDialog() {
        this.setState({ open: true });
    }
    handleClose() {
        this.setState({ open: false });
      }
    
    handleClick = (e) => {
        // access to e.target here
        console.log(e.target.src);
        this.sr=e.target.src;
        this.openDialog();
    }
    render() {
        var settings = {
            dots: false,
            accessibility: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        };
        
        return (
            <div id="content">
                
                <div className="half">
                </div>
                <Dialog  open={this.state.open} onClose={this.handleClose.bind(this)} >
                    <DialogContent className="dia-modal"><img className="img-modal" src={this.sr}></img></DialogContent>
                </Dialog>
                <Navbar2/>
                <div className="card proj-card">
                    <div className="proj-cont">
                        <div className="row">
                            <center><h2 className="center apptitle" ><img className="img-responsive" src={DITAppLogo}></img><b>&nbsp;DITU Student App</b></h2></center>
                            <div className="slide-obj">
                                <Slider  {...settings}>
                                    <div className="slide-item">
                                        <img id="myImg" onClick={((e) => this.handleClick(e))} src={ditapp1} alt="Preview"/>
                                    </div>
                                    <div className="slide-item">
                                        <img id="myImg" onClick={((e) => this.handleClick(e))} src={ditapp2} alt="Preview"/>
                                    </div>
                                    <div className="slide-item">
                                        <img id="myImg" onClick={((e) => this.handleClick(e))} src={ditapp3} alt="Preview"/>
                                    </div>
                                    <div className="slide-item">
                                        <img id="myImg" onClick={((e) => this.handleClick(e))} src={ditapp4} alt="Preview"/>
                                    </div>
                                    <div className="slide-item">
                                        <img id="myImg" onClick={((e) => this.handleClick(e))} src={ditapp5} alt="Preview"/>
                                    </div>
                                    <div className="slide-item">
                                        <img id="myImg" onClick={((e) => this.handleClick(e))} src={ditapp6} alt="Preview"/>
                                    </div>
                                </Slider>
                            </div>
                            <div className="col s12 m4">

                                <h4>Description</h4>
                            </div>
                            <div className="col s12 m8">
                                <h6>This app was created to help DIT University, Dehradun students to make their life little bit easier.<br /><br />This App has following features<br /><br />- DIT News - Used College Website Api to Get All Announcements and Send Notification using Cron Jobs<br /><br />- Attendance Calculator - A Calculator to calculate the number of days you can bunk or attend to make sure your attendance stay above 75%<br /><br />- Old Papers - Got pdf of all papers from college library and made it easily searchable and downlable<br /><br />- Easy to use SAP - Used POST method from android browser and send user credentials to make easy accessible SAP<br /><br />- Chat - Used Firebase Realtime Database for Chat and PHP to store and get files for Chat<br /><br />- Events - All club events in one place
                                <br /><br />-My Files - A kind of Cloud Storage with Website(<Link to={'/dituappweb'} key='9'>More Info Here</Link>) to store files and codes made during practicals in college<br /><br /></h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4">
                                <h4>Technologies Used</h4>
                            </div>
                            <div className="col s12 m8">
                                <h6>Java(Core), Android, XML, PHP, MySQL Firebase</h6>
                                <h6>PHP is used for retriving and sending data(text,images,docs) to server</h6>
                                <h6>Firebase -  for Google Authentication, Realtime Database for Chat, Storage for Profile Pic, And FCM for push notifications</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4">
                                <h4>Stats</h4>
                            </div>
                            <div className="col s12 m8">
                                <img onClick={((e) => this.handleClick(e))} src={DITAppStats} alt="DITU Student App Stats" width="500px" />
                                <p>Last 90 days stats, updated on September 23, 2019</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4">
                                <h4>Get It</h4>
                            </div>
                            <div className="col s12 m8">
                                <a href="https://play.google.com/store/apps/details?id=com.ditu.yash.timetableditu" target="_blank" rel="noopener noreferrer"><img src={playstore} alt="Playstore Icon" width="70px" target="_blank"/></a>&nbsp;&nbsp;&nbsp;
                                <a   rel="noopener noreferrer"><img src={github}  width="65px"></img></a>
                        
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        )
    }
}

export default DituApp