import React from 'react'
import Slider from "react-slick";
import Navbar2 from '../layout/Navbar2'
import ditweb2 from '../assets/img/ditweb1.png';
import ditweb from '../assets/img/dituweb1.webp';
import ditweb1 from '../assets/img/dituweb2.webp';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
class DituAppWeb extends React.Component {
    state = {
        open: false
    };
    sr="";
    openDialog() {
        this.setState({ open: true });
    }
    handleClose() {
        this.setState({ open: false });
      }
    
    handleClick = (e) => {
        // access to e.target here
        //console.log(e.target.src);
        this.sr=e.target.src;
        this.openDialog();
    }
    render() {
        var settings = {
            dots: false,
            accessibility: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            
            <div id="content">
                <Dialog  open={this.state.open} onClose={this.handleClose.bind(this)} >
                    <DialogContent className="dia-modal"><img className="img-modal imgmod" src={this.sr}></img></DialogContent>
                </Dialog>
                <Navbar2 />
                <div className="half">
                </div>
                <div className="card proj-card">
                    <div className="proj-cont">
                        <div className="row">  
                            <h2 className="center"><b>DITU Student App Website</b></h2>
                            <div className="slide-obj">
                                <Slider  {...settings}>
                                    <div className="slide-item">
                                        <img id="myImg" onClick={((e) => this.handleClick(e))} src={ditweb} alt="Preview"/>
                                    </div>
                                    <div id="myImg" className="slide-item myImg">
                                        <img onClick={((e) => this.handleClick(e))} src={ditweb1} alt="Preview"/>
                                    </div>
                                    <div id="myImg" className="slide-item myImg">
                                        <img onClick={((e) => this.handleClick(e))} src={ditweb2} alt="Preview"/>
                                    </div>

                                </Slider>
                            </div>
                            <div className="col s12 m4">

                                <h4>Description</h4>
                            </div>
                            <div className="col s12 m8">
                                <h6>
                                    <p>DITU Student App Website</p>
                                    <p>As CSE students when code in the computer labs they have to store the codes and screenshots so that they can later print it in Practical File. To store they may use Gmail or Pen Drives. To make this task easy, this website was created.</p>
                                    <p>It has following features -&nbsp;</p>
                                    <ol>
                                        <li>Cloud Storage - Anyone can store files of specified type and can also access them through the App.</li>
                                        <li>CodeBin - Similar to Pastebin, users can store their codes.</li>
                                        <li>CodeShare - Students of same class can access codes shared by their classmates&nbsp;</li>
                                        <li>Old Papers - Collection of all Old Papers of DIT University</li>
                                    </ol>
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4">
                                <h4>Skills Used</h4>
                            </div>
                            <div className="col s12 m8">
                                <h6>HTML, CSS, PHP, MySQL</h6>
                                <h6>PHP is used for retriving and sending data(text,images,docs) to server</h6>
                                <h6>SQL for storing all the information</h6>
                                <h6>AJAX for searching the old papers by name or subject code</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4">
                                <h4>View It</h4>
                            </div>
                            <div className="col s12 m8">
                                <a href="http://ditustudentapp.ml/dashboard/" target="_blank" rel="noopener noreferrer">http://ditustudentapp.ml/dashboard/</a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default DituAppWeb