import React, { Component } from 'react'
import Navbar from '../layout/Navbar'
import Navbar3 from '../layout/Navbar3'
import Home from '../sections/Home'
import About from '../sections/About'
import Resume from '../sections/Resume'
import Projects from '../sections/Projects'
class Dashboard extends Component {
    componentDidMount(){
        window.side();
    }
    render() {
        return (
            <div id="dashboard">
                <Home />
                <Navbar/>
                <About />
                <Resume />
                <Projects />
            </div>
        )
    }
}

export default Dashboard