import React, { Component } from 'react'
import {Link as Link2} from 'react-router-dom'
import "materialize-css/dist/css/materialize.min.css";
class Navbar2 extends Component {
    
    render() {
        return (
            <div className="naviga">
                <nav id="menu2" className="nav-wrapper grey darken-3">
                    <div className="container">
                        <Link2 to='/' className="brand-logo center">Yash Agarwal</Link2>
                        <ul className="left backbut">
                        <li><Link2 to='/'><i className="material-icons">arrow_back</i></Link2></li>
                       
                        </ul>
                      </div>  
                </nav>
            </div>
        )
    }
}

export default Navbar2