import React from 'react'
import propic from '../assets/img/yash1.png';
class Home extends React.Component {
    render() {

        return (
            <div id="content">
                <div className="home">
                    <center>

                        <img data-aos="zoom-in" src={propic} width='200px' alt="Yash" /><br /><br />
                        <h1 data-aos="flip-left">Yash Agarwal</h1><br />
                        <h2 data-aos="flip-right">- Android App Developer</h2>
                        <br /><br /><br /><br /><br /><br />
                    </center>
                </div>
            </div>
        )
    }
}

export default Home