import React from 'react'
import Slider from "react-slick";
import Navbar2 from '../layout/Navbar2'
class Youthopia18 extends React.Component {
    render() {
        var settings = {
            dots: false,
            accessibility: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div id="content">
                <Navbar2/>
                <div className="half">
                </div>
                <div className="card proj-card">
                    <div className="proj-cont">
                        <div className="row">
                            <h2 className="center"><b>Youthopia'18</b></h2>
                            <div className="slide-obj">
                                <Slider  {...settings}>
                                    <div className="slide-item">
                                        <img src="https://lh3.googleusercontent.com/2_9_qL0bixISFX18v2jmRzVrrzcHo66dcCX0jg3ZtXA7xc-1ckMzAsdsWr7bPtvapr2x=w1536-h754-rw" alt="Preview"/>
                                    </div>
                                    <div className="slide-item">
                                        <img src="https://lh3.googleusercontent.com/6ZsXqpkV-waLLd3KA0htY9R0N58VmtPWZNJ6qtrBdcSw1lnkHg49WNfdc_E0J0Jo3Fc=w1536-h754-rw" alt="Preview"/>
                                    </div>
                                    <div className="slide-item">
                                        <img src="https://lh3.googleusercontent.com/SluHPJquJGUGFTgkoWUmbeIREwpmuUTguV7631KOIjZwrHOS_Ohn7QrGi9S2-HIHNw=w1536-h754-rw" alt="Preview"/>
                                    </div>
                                  
                                </Slider>
                            </div>
                            <div className="col s12 m4">

                                <h4>Description</h4>
                            </div>
                            <div className="col s12 m8">
                                <h6><div>
                                    <div>App for Techno-Cultural Fest Organised by DIT University, Dehradun</div>
                                    <div>&nbsp;</div>
                                    <div>It has following features -&nbsp;</div>
                                    <ol>
                                        <li>List and Description of all Events of Youthopia'18</li>
                                        <li>Students can register for any event through this app</li>
                                        <li>Feeds to show, what's currently happening in Youthopia</li>
                                        <li>Push Notifications</li>
                                        <li>List of all Committee members.</li>
                                    </ol>
                                </div></h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4">
                                <h4>Skills Used</h4>
                            </div>
                            <div className="col s12 m8">
                                <h6>Java(Core), Android, XML, PHP, MySQL Firebase</h6>
                                <h6>PHP is used for retriving and sending data(text,images) to server</h6>
                                <h6>Firebase -  for Google Authentication, And FCM for push notifications</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4">
                                <h4>Get It</h4>
                            </div>
                            <div className="col s12 m8">
                                <a href="https://play.google.com/store/apps/details?id=com.dituniversity.youthopia" target="_blank" rel="noopener noreferrer"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Get_it_on_Google_play.svg/1280px-Get_it_on_Google_play.svg.png" alt="Playstore Icon" width="200px" target="_blank"/></a>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        )
    }
}

export default Youthopia18