import React from 'react'
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Pdf from '../assets/yash_agarwal_resume.pdf';
const Resume = () => {
   
    return(
        <div id="content">
            
            <div className="resume">
                <div className="row">
                    <div className="col s12 m4" data-aos="fade-right">
                        <h4>Education</h4>
                    </div>
                    <div className="col s12 m8" data-aos="fade-left">
                        <h5><b>DIT University, Dehradun</b></h5>
                        <p className="info">Bachelor in Computer Science and Engineering <span>•</span> <em className="date">April 2020</em></p>
                        <p className="light">CGPA 7.1</p>
                        <h5><b>Dr. Virendra Swarup Public School, Lucknow</b></h5>
                        <p className="info">10+2 ISC Board<span>•</span> <em className="date">March 2015</em></p>
                        <p className="light">Percentage - 75.6</p>
                        <h5><b>St. Norbert School, Bahraich</b></h5>
                        <p className="info">High School<span>•</span> <em className="date">March 2013</em></p>
                        <p className="light">Percentage - 79</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m4" data-aos="fade-right">
                        <h4>Skills</h4>
                    </div>
                    <div className="col s12 m8" data-aos="fade-left">
                        <h5><b>Advanced</b></h5>
                        <h6>Java(Core), Android</h6>
                        <h5><b>Intermediate</b></h5>
                        <h6>Python, Angular, ReactJs, HTML, CSS, Javascript, PHP, MySQL, C, C++</h6>
                        <h5><b>Beginner</b></h5>
                        <h6>Machine Learning, Deep Learning</h6>
                        <h5><b>Operating Systems</b></h5>
                        <h6>Windows (XP,7,10) , Linux (Ubuntu)</h6>
                        <h5><b>Platforms</b></h5>
                        <h6>Firebase</h6>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col s12 m4" data-aos="fade-right">
                        <h4>Certificates</h4>
                    </div>
                    <div className="col s12 m8" data-aos="fade-left">
                        <h5><b>Code Cracker(Winner)</b></h5>
                        <h6>Coding Competition Organised by TechLive Club, DIT University <span>•</span> <em className="date">Jan 2017</em></h6>
                        <h5><b>Game of Codes (Runner Up)</b></h5>
                        <h6>Coding Competition Organised by CodeGenX Club, DIT University <span>•</span> <em className="date">March 2017</em></h6>
                        <h5><b>Code Busters(Runner Up)</b></h5>
                        <h6>Coding Competition Organised by DIT Impulse Club during Youthopia'17, DIT University <span>•</span> <em className="date">Nov 2017</em></h6>
                        <h5><b>WebDroid(Winner)</b></h5>
                        <h6>Android App Making Competition Organised by DIT Hackathon Club, DIT University <span>•</span> <em className="date">Sep 2018</em></h6>
                        <h5><b>Code Busters(Winner)</b></h5>
                        <h6>Coding Competition Organised by DIT Impulse Club during Youthopia'18, DIT University <span>•</span> <em className="date">Nov 2018</em></h6>
                        
                    </div>
                </div> */}
                <br/><br/>
                <center><Button href = '/assets/yash_agarwal_resume.pdf' target = "_blank" variant="contained" size="large" color="primary" aria-label="delete" >
                <i className="material-icons">cloud_download</i>
                    &nbsp;&nbsp;&nbsp;Download Resume
                </Button></center>
            </div>
        </div>
    )
}

export default Resume