import React from 'react'
const Contact = () => {
    var  today = new Date();
    var dt=today.getFullYear();
    return (
        <div id="content">
            <div className="contact">
                <div className="row">
                    <div className="col s12 m12">
                        <h2>Contact Me</h2>
                        <h6>I'm always looking for opportunities to work on exciting projects. As I will be Graduating in 2020, I'm currently looking for jobs.</h6>
                    </div>
                </div>
                <div className="row" data-aos="fade-up">
                    <div className="col s12 m12 contact-main">
                    <h5><a href="mailto:yashagl1620@gmail.com" className="contact-email" title="Email">Mail Me - yashagl1620@gmail.com</a></h5>
                    <h5><a href="tel:+918004980177" className="contact-email" title="Call">Call Me - +918004980177</a></h5>
                    </div>
                </div>
                <div className="row" data-aos="fade-up">
                        <h3>Find me elsewhere</h3>
                        <br/>
                        <ul>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/yashagldit" title="GitHub"><i className="fa fa-github"></i></a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/yashagl" title="LinkedIn"><i className="fa fa-linkedin"></i></a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://facebook.com/yashagl9" title="Facebook"><i className="fa fa-facebook"></i></a></li>
                            {/* <li><a target="_blank" rel="noopener" href="https://twitter.com/yashagl18" title="Twitter"><i className="fa fa-twitter"></i></a></li> */}
                            {/* <li><a target="_blank" rel="noopener" href="" title="PayPal"><i className="fab fa-paypal"></i></a></li> */}
                        </ul>
                    </div>
                    <div className="copyright">
                        <center><p>&copy; {dt}, Made By -  Yash Agarwal</p></center>
                    </div>
            </div>
            
        </div>
    )
}

export default Contact