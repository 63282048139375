import React from 'react'
import Slider from "react-slick";
import Navbar2 from '../layout/Navbar2'
import snake from '../assets/img/snake_a.png';
import snake1 from '../assets/img/snake_astar.png';
import snake2 from '../assets/img/snake_astar2.png';
import github from '../assets/img/github.png'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
class Snake extends React.Component {
    state = {
        open: false,
        yt: false
    };
    sr="";
    openDialog() {
        this.setState({ open: true });
    }
    handleClose() {
        this.setState({ open: false });
      }
      openDialog1() {
        this.setState({ yt: true });
    }
    handleClose1() {
        this.setState({ yt: false });
      }
    
    handleClick = (e) => {
        // access to e.target here
        //console.log(e.target.src);
        this.sr=e.target.src;
        this.openDialog();
    }
    handleClick1 = (e) => {
        // access to e.target here
        //console.log(e.target.src);
        this.sr=e.target.src;
        this.openDialog1();
    }
    render() {
        var settings = {
            dots: false,
            accessibility: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            
            <div id="content">
                <Dialog  open={this.state.open} onClose={this.handleClose.bind(this)} >
                    <DialogContent className="dia-modal"><img className="img-modal" src={this.sr}></img></DialogContent>
                </Dialog>
                <Dialog  open={this.state.yt} onClose={this.handleClose1.bind(this)} >
                    <DialogContent className="dia-modal">
                    <iframe className="ytvideo" style={{top: 0,left: 0,display:"block"}} src={'https://www.youtube.com/embed/J8Ocx0567Cg'} frameBorder="0"/>
                </DialogContent></Dialog>
                <Navbar2 />
                <div className="half">
                </div>
                <div className="card proj-card">
                    <div className="proj-cont">
                        <div className="row">  
                            <h2 className="center"><b>Snake Game</b></h2>
                            <div className="slide-obj">
                                <Slider  {...settings}>
                                    
                                    <div id="myImg" className="slide-item myImg">
                                        <img onClick={((e) => this.handleClick(e))} src={snake1} alt="Preview"/>
                                    </div>
                                    <div id="myImg" className="slide-item myImg">
                                        <img onClick={((e) => this.handleClick(e))} src={snake2} alt="Preview"/>
                                    </div>
                                    <div className="slide-item">
                                    <img onClick={((e) => this.handleClick1(e))} src={snake} alt="Preview"/>
                                    </div>

                                </Slider>
                            </div>
                            <div className="col s12 m4">

                                <h4>Description</h4>
                            </div>
                            <div className="col s12 m8">
                                <h6>
                                    <p>Snake game using Pygame Python with A* path finding algorithm</p>
                                    <p><a href="https://github.com/yashagldit/Snake-Game-Python" target="_blank">More Info Here</a></p>
                                    
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4">
                                <h4>Demo</h4>
                            </div>
                            <div className="col s12 m8">
                            <div
                                        className="video"
                                        style={{
                                            position: "relative",
                                            paddingBottom: "56.25%" /* 16:9 */,
                                            paddingTop: 25,
                                            height: 0
                                        }}
                                        >
                                        <iframe
                                            style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%"
                                            }}
                                            src={`https://www.youtube.com/embed/J8Ocx0567Cg`}
                                            frameBorder="0"
                                        />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4">
                                <h4>Skills Used and Learned</h4>
                            </div>
                            <div className="col s12 m8">
                                <br/>
                                <h6><p>Python, Pygame</p></h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4">
                                <h4>View It</h4>
                            </div>
                            <div className="col s12 m8">
                                <a href="https://github.com/yashagldit/Snake-Game-Python" target="_blank" rel="noopener noreferrer"><img src={github} ></img></a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default Snake