import React from 'react'
const About = () => {
    return(
        <div id="content" data-aos="fade-up">
            <div className="about" >
                <h5>HI, I'M YASH</h5>
                <h3>I love Developing Applications and Learning about New Technologies</h3>                
            </div>
        </div>
    )
}

export default About