import React, { Component } from 'react';

import Dashboard from './components/dashboard/Dashboard'
import DituApp from './components/projects/DituApp'
import DituAppWeb from './components/projects/DituAppWeb'
import Youthopia17 from './components/projects/Youthopia17'
import Youthopia18 from './components/projects/Youthopia18'
import Snake from './components/projects/Snake'
import ScrollToTop from './components/layout/ScrollToTop'
import Contact from './components/sections/Contact'

import { HashRouter,Switch,Route} from 'react-router-dom'
import { white } from 'material-ui/styles/colors';
const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');
class App extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    hideLoader();
  }
  
  render() {
    const { loading } = this.state;
    console.log(loading);
    
      return (
        <HashRouter>
          <div className="App" style={{backgroundColor:white}}>
          <ScrollToTop>
            <Switch>
              <Route exact path='/' component={Dashboard}/>
              <Route path='/dituapp' component={DituApp}/>
              <Route path='/dituappweb' component={DituAppWeb}/>
              <Route path='/youthopia17' component={Youthopia17}/>
              <Route path='/youthopia18' component={Youthopia18}/>
              <Route path='/snake' component={Snake}/>
              <Route component={Dashboard} />
            </Switch>
            <Contact/>
            </ScrollToTop>
          </div>
        </HashRouter>
      ); 
  }
}

export default App;
