import React from 'react'
import { Link } from 'react-router-dom'
import ditapppic from '../assets/img/ditapppic.png';
import youthopiapic from '../assets/img/youthopia.png';
import android_logo from '../assets/img/android-logo.png';
import python_logo from '../assets/img/python-logo.png';
import snake from '../assets/img/snake.png';
import htmlpic from '../assets/img/html-css.png';
import reactpic from '../assets/img/react-logo.png';
const Projects = () => {
    return (
        <div id="project" className="projects">
            <div className="proj-head">

                <h3>My Projects</h3>
            </div>
            <div className="proj-li">
                <div className="row">
                    <div className="col s12 m4" data-aos="fade-right">
                        <Link to={'/dituapp'} key='1'>
                            <div className="card project-item android">
                                <div className="card-content grey-text text-darken-3">
                                    <div className="inactive">
                                        <center>
                                            <img src={ditapppic} width="100px" align="center" alt="DITU Student App Logo"/>
                                            <span className="card-title "><b>DITU Student App</b></span>
                                            <p>An app for DIT University Students</p>
                                        </center>
                                    </div>
                                    <div className="active android">
                                        <center>
                                            <img src={android_logo} width="100px" alt="Android Logo"/>
                                            <span className="card-title ">Click to Know More</span>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col s12 m4" data-aos="fade-down">
                        <Link to={'/dituappweb'} key='2'>
                            <div className="card project-item web">
                                <div className="card-content grey-text text-darken-3">
                                    <div className="inactive">
                                        <center>
                                            <img src={ditapppic} width="100px" height="150px" align="center"  alt="DITU Student App Logo"/>
                                            <span className="card-title "><b>DITU Student App Website</b></span>
                                            <p>An app for DIT University Students</p>
                                        </center>
                                    </div>
                                    <div className="active android">
                                        <center>
                                            <img src={htmlpic} width="100px" height="150px" alt="HTML CSS PHP Logo"/>
                                            <span className="card-title ">Click to Know More</span>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col s12 m4" data-aos="fade-left">
                        <Link to={'/youthopia17'} key='3'>
                            <div className="card project-item android">
                                <div className="card-content grey-text text-darken-3">
                                    <div className="inactive">
                                        <center>
                                            <img src={youthopiapic} width="100px" height="150px" align="center" alt="Youthopia Logo" />
                                            <span className="card-title "><b>Youthopia'17</b></span>
                                            <p>An app for Techno-Cultural Fest organised by DIT University</p>
                                        </center>
                                    </div>
                                    <div className="active android">
                                        <center>
                                            <img src={android_logo} width="100px" height="150px" alt="Android Logo"/>
                                            <span className="card-title ">Click to Know More</span>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m4" data-aos="fade-right">
                    <Link to={'/youthopia18'} key='4'>
                        <div className="card project-item android">
                                <div className="card-content grey-text text-darken-3">
                                    <div className="inactive">
                                        <center>
                                            <img src={youthopiapic} width="100px" height="150px" align="center" alt="Youthopia Logo"/>
                                            <span className="card-title "><b>Youthopia'18</b></span>
                                            <p>An app for Techno-Cultural Fest organised by DIT University</p>
                                        </center>
                                    </div>
                                    <div className="active android">
                                        <center>
                                            <img src={android_logo} width="100px" height="150px" alt="Android Logo"/>
                                            <span className="card-title ">Click to Know More</span>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col s12 m4" data-aos="fade-down">
                        <div className="card react project-item">
                        <div className="card-content grey-text text-darken-3">
                                    <div className="inactive">
                                        <center>
                                            <img src={reactpic} width="100px" height="150px" align="center" alt="React Logo"/>
                                            <span className="card-title "><b>Portfolio</b></span>
                                            <p>Portfolio of Yash Agarwal</p>
                                        </center>
                                    </div>
                                    <div className="active android">
                                        <center>
                                            <img src={reactpic} width="100px" alt="React Logo"/>
                                            <span className="card-title ">This Website</span>
                                        </center>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="col s12 m4" data-aos="fade-left">
                    <Link to={'/snake'} key='5'>
                        <div className="card project-item python">
                            <div className="card-content grey-text text-darken-3">
                                    <div className="inactive">
                                        <center>
                                            { <img src={snake} width="100px" height="150px" align="center" />}
                                            <span className="card-title "><b>Snake Game</b></span>
                                            <p>Snake game using Pygame Python with A* path finding algorithm</p>
                                        </center>
                                    </div>
                                    <div className="active python">
                                        <center>
                                            <img src={python_logo} width="100px" height="150px" alt="Python Logo"/>
                                            <span className="card-title ">Click to Know More</span>
                                        </center>
                                    </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects