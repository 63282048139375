import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Link as Link2 } from 'react-router-dom'
import "materialize-css/dist/css/materialize.min.css";
import { Link } from "react-scroll";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className="navig">
      <AppBar color="secondary" position="relative" >
        <Toolbar  >
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">

          </IconButton>
          <Typography variant="h6" className={classes.title}>
            News
          </Typography>
          <Button color="inherit">
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-20}
              duration={500}>
              Home
            </Link>
          </Button>
          <Button>
            <Link
              activeClass="active"
              to="resume"
              spy={true}
              smooth={true}
              offset={-20}
              duration={500}>
              Resume
            </Link>
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}